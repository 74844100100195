import { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";

import useAxios from "../../utils/useAxios";
import PermissionContext from "../../context/PermissionContext";
import FormulationsTabContext from "../../context/FormulationsTabContext";
import DrawerContext from "../../context/DrawerContext";
import { resObj, paginationVal } from "../../utils/constants";
import AutoLogout from "../../utils/useAutoLogout";

import Pagination from '@mui/material/Pagination';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from "@mui/material/Box";

import SideDrawer from "../../components/layout/SideDrawer";
import FormulationsHeader from "../../components/analytics/FormulationsHeader";
import Loader from "../../components/layout/Loader";
import ErrorModal from "../../components/layout/ErrorModal";
import TableWithCheckbox from "../../components/analytics/TableWithCheckbox";
import NoData from "../../components/layout/NoData";
import DeleteModal from "../../components/layout/DeleteModal";
import CommentModalForm from "../../components/analytics/CommentModalForm";
import SuccessAlert from "../../components/layout/SuccessAlert";
import PlateBarcodeModal from "../../components/analytics/PlateBarcodeModal";

const FormulationsPage = () => {
  const api = useAxios();
  const navigate = useNavigate();

  const { permisionsList, checkUserPermission } = useContext(PermissionContext);
  const { tabValue, setTabValue } = useContext(FormulationsTabContext);
  const { drawerValue, setDrawerValue } = useContext(DrawerContext);

  useEffect(() => {
    if(permisionsList.length > 0) checkUserPermission(['Analytics-View Data', 'Analytics-Manage Data']);
  }, [permisionsList]);

  const lnpSampleHeading = ['SI No', 'Sample Name', 'Ionisable', 'Sterol', 'Neutral', 'PEG'];
  const aqControlHeading = ['SI No', 'LNP Plate', 'Name', 'Ribogreen'];
  const transfectionControlHeading = ['SI No', 'LNP Sample', 'Name', 'Transfection'];
  const emptyWellsHeading = [
    'SI No', 'LNP Plate', 'PSD Summary', 'PSD Graph', 'Ribogreen', 'Transfection'
  ];
  const deleteLnpSampleModalTitle = 'LNP Sample Delete';
  const deleteLnpSampleModalMessage = `Are you sure you want to delete? 
    (All related informations, LNP Paramters, PSD Summary, PSD Graph, 
    Ribogreen, Transfection and Comments will be lost)`;
  const deleteAqControlModalTitle = 'AQ Control Delete';
  const deleteAqControlModalMessage = `Are you sure you want to delete? 
    (All related informations, AQ Controls and Ribogreen will be lost)`;
  const deleteTransfectionControlModalTitle = 'Transfection Control Delete';
  const deleteTransfectionControlModalMessage = `Are you sure you want to delete? 
    (All related informations, Transfection Controls and Transfection will be lost)`;
  const deleteEmptyWellsModalTitle = 'Empty Wells Delete';
  const deleteEmptyWellsModalMessage = `Are you sure you want to delete? 
    (All related informations, PSD Summary, PSD Graph, Ribogreen and Transfection will be lost)`;
  const barcodeQualityModalTitle = 'Plate Barcode Enable/Disable'
  const barcodeQualityModalMessage = `Are you sure you want to enable/disable plate barcode?
    (All realted Quality Check Informations will be lost)`;

  const [apiRes, setApiRes] = useState(resObj);
  const [tableHeading, setTableHeading] = useState(lnpSampleHeading);
  const [formulationList, setFormulationList] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isBulkDelete, setIsBulkDelete] = useState(false);
  const [sampleId, setSampleId] = useState('');
  const pageData = useRef(paginationVal);
  const [formulationsIdList, setFormulationsIdList] = useState([]);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [comment, setComment] = useState('');
  const [plateModalOpen, setPlateModalOpen] = useState(false);
  const [plateBarcodesList, setPlateBarcodesList] = useState([]);
  const [uncheckedBarcodes, setUncheckedBarcodes] = useState({
    dls: [],
    ribogreen: [],
    transfection: [],
  });
  const [selectedBarcodes, setSelectedBarcodes] = useState([]);
  const [qualityCheckedPlates, setQualityCheckedPlates] = useState([]);
  const [showBarcodeQualityModal, setShowBarcodeQualityModal] = useState(false);

  const bulkCommentCreate = () => {
    setShowCommentModal(true);
  };

  useEffect(() => {
    setFormulationList([]);
    pageData.current.pageNumber = 1;
    if (tabValue === 'LNP Samples') {
      setTableHeading(lnpSampleHeading);
      getLNPSamples();
    } else if (tabValue === 'AQ Controls') {
      setTableHeading(aqControlHeading);
      getAQControls();
    } else if (tabValue === 'Transfection Controls') {
      setTableHeading(transfectionControlHeading);
      getTransfectionControls();
    } else {
      setTableHeading(emptyWellsHeading);
      getEmptyWells();
    }
  }, [tabValue]);

  const getLNPSamples = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/analytics/lnp/samples/list/get', {
        params: { 
          search_term: pageData.current.searchTerm,
          page_number: +pageData.current.pageNumber,
        }
      });
      
      if (response.status === 200) {
        setApiRes({ ...apiRes, loading: false });
        setFormulationList(response.data.data);
        pageData.current.pageCount = response.data.page_count;
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'LNP Samples Get',
        loading: false,
      });
    }
  };

  const getAQControls = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/analytics/aq/controls/list/get', {
        params: { 
          search_term: pageData.current.searchTerm,
          page_number: +pageData.current.pageNumber,
        }
      });
      
      if (response.status === 200) {
        setApiRes({ ...apiRes, loading: false });
        setFormulationList(response.data.data);
        pageData.current.pageCount = response.data.page_count;
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'LNP Samples Get',
        loading: false,
      });
    }
  };

  const getTransfectionControls = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/analytics/transfection/controls/list/get', {
        params: { 
          search_term: pageData.current.searchTerm,
          page_number: +pageData.current.pageNumber,
        }
      });
      
      if (response.status === 200) {
        setApiRes({ ...apiRes, loading: false });
        setFormulationList(response.data.data);
        pageData.current.pageCount = response.data.page_count;
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'LNP Samples Get',
        loading: false,
      });
    }
  };

  const getEmptyWells = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/analytics/empty/wells/list/get', {
        params: { 
          search_term: pageData.current.searchTerm,
          page_number: +pageData.current.pageNumber,
        }
      });
      
      if (response.status === 200) {
        setApiRes({ ...apiRes, loading: false });
        setFormulationList(response.data.data);
        pageData.current.pageCount = response.data.page_count;
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'LNP Samples Get',
        loading: false,
      });
    }
  };

  const navigateToEdit = fid => {
    if (tabValue === 'LNP Samples') {
      navigate(`/lnpsample/detail/${fid}`);
    } else if (tabValue === 'AQ Controls') {
      navigate(`/aqcontrol/detail/${fid}`);
    } else if (tabValue === 'Transfection Controls') {
      navigate(`/transfectioncontrol/detail/${fid}`);
    } else {
      navigate(`/empty/wells/detail/${fid}`);
    }
  };

  const handlePaginationClick = (e, pageNumber) => {
    pageData.current.pageNumber = pageNumber;
    if (tabValue === 'LNP Samples') {
      getLNPSamples();
    } else if (tabValue === 'AQ Controls') {
      getAQControls();
    } else if (tabValue === 'Transfection Controls') {
      getTransfectionControls();
    } else {
      getEmptyWells();
    }
  };

  const deleteLNPSample = async () => {
    try {
      setDeleteModalOpen(false);
      setApiRes({...apiRes, loading: true});
      const response = await api.delete(`/api/analytics/lnp/sample/delete/${sampleId}`);

      if (response.status === 200) {
        getLNPSamples();
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: 'Sample deleted successfully',
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Sample Delete',
        loading: false,
      });
    }
  };

  const deleteAQControl = async () => {
    try {
      setDeleteModalOpen(false);
      setApiRes({...apiRes, loading: true});
      const response = await api.delete(`/api/analytics/aq/control/delete/${sampleId}`);

      if (response.status === 200) {
        getAQControls();
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: 'AQ Control deleted successfully',
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'AQ Control Delete',
        loading: false,
      });
    }
  };

  const deleteTransfectionControl = async () => {
    try {
      setDeleteModalOpen(false);
      setApiRes({...apiRes, loading: true});
      const response = await api.delete(`/api/analytics/transfection/control/delete/${sampleId}`);

      if (response.status === 200) {
        getTransfectionControls();
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: 'Transfection Control deleted successfully',
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Transfection Control Delete',
        loading: false,
      });
    }
  };

  const deleteEmptyWell = async () => {
    try {
      setDeleteModalOpen(false);
      setApiRes({...apiRes, loading: true});
      const response = await api.delete(`/api/analytics/empty/wells/delete/${sampleId}`);

      if (response.status === 200) {
        getEmptyWells();
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: 'Empty Wells deleted successfully',
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Empty Wells Delete',
        loading: false,
      });
    }
  };

  const bulkDelete = async () => {
    try {
      setDeleteModalOpen(false);
      setApiRes({...apiRes, loading: true});

      const formData = new FormData();
      formData.append("lnp_sample_id_list", JSON.stringify(formulationsIdList));
      const response = await api.post('/api/analytics/lnp/sample/bulk/delete', formData);

      if (response.status === 200) {
        getLNPSamples();
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: response.data,
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Bulk Comment',
        loading: false,
      });
    }
  };

  const addComment = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const formData = new FormData();
      formData.append("comment", comment);
      formData.append("lnp_sample_id_list", JSON.stringify(formulationsIdList));
      const response = await api.post('/api/user/lnp/sample/comment/bulk/create', formData);

      if (response.status === 200) {
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: response.data,
          loading: false,
        });
        setComment('');
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Bulk Comment',
        loading: false,
      });
    }
  };

  const getPlateBarcodes = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/analytics/plate/barcodes/get');
      
      if (response.status === 200) {        
        setApiRes({ ...apiRes, loading: false });
        setPlateModalOpen(true);
        setPlateBarcodesList(response.data.data_list);
        setQualityCheckedPlates(response.data.quality_checked_plates)        
        
        const barcodes = response.data.data_list.reduce(
          (acc, obj) => {
            acc.dls.push(
              ...obj?.dls_barcode_list
                .filter(item => !item.is_active).map(item => item.barcode)
            );
            acc.ribogreen.push(
              ...obj?.ribogreen_barcode_list
                .filter(item => !item.is_active).map(item => item.barcode)
            );
            acc.transfection.push(
              ...obj?.transfection_barcode_list
                .filter(item => !item.is_active).map(item => item.barcode)
            );
            return acc;
          },
          { dls: [], ribogreen: [], transfection: [] }
        );        
        
        setUncheckedBarcodes(prev => ({
          ...prev,
          ...barcodes,
        }));
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Plate Barcodes Get',
        loading: false,
      });
    }
  };

  const handlePlatesChange = (e, type, barcode, plateName) => {
    const isChecked = e.target.checked;
    let data = [...selectedBarcodes];

    // Find the index of the matching object based on barcode and type
    const index = data.findIndex(
      el => el.barcode === barcode && el.type === type && el.plate === plateName
    );

    if (index === -1) {
      // If not found, add a new object
      data.push({
        barcode: barcode,
        type: type,
        is_active: isChecked,
        plate: plateName
      });
    } else {
      // If found, update the is_active property
      data[index] = { ...data[index], is_active: isChecked };
    }
    setSelectedBarcodes(data);

    if (isChecked) {
      setUncheckedBarcodes(prev => ({
        ...prev,
        [type]: prev[type] ? prev[type].filter(item => item !== barcode) : [],
      }));
    } else {
      setUncheckedBarcodes(prev => ({
        ...prev,
        [type]: prev[type] ? [...prev[type], barcode] : [barcode],
      }));
    }
  };
  const plateBarcodeToggle = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      setShowBarcodeQualityModal(false);

      const formData = new FormData();
      formData.append("barcodes", JSON.stringify(selectedBarcodes));
      const response = await api.post('/api/analytics/plate/barcode/toggle', formData);

      if (response.status === 200) {
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: response.data,
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Plate Barcode Toggle',
        loading: false,
      });
    }
  };

  const handleTogglePlateBarcode = () => {
    const platesArr = selectedBarcodes.map(item => item.plate);
    const hasMatch = platesArr.some(item => qualityCheckedPlates.includes(item));
    setShowBarcodeQualityModal(hasMatch);
    if (!hasMatch) {
      plateBarcodeToggle();
    }
  };

  return(
    <AutoLogout>
      <SideDrawer drawerValue={drawerValue} setDrawerValue={setDrawerValue} />
      {apiRes.loading && <Loader />}
      <div className="mainContainer" style={{ marginLeft: drawerValue.drawerWidth }}>
        {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
        <FormulationsHeader 
          title="Formulations" 
          handleClick={bulkCommentCreate} 
          setDeleteModalOpen={setDeleteModalOpen}
          setIsBulkDelete={setIsBulkDelete} 
          search={
            tabValue === 'LNP Samples' ? getLNPSamples : 
            tabValue === 'AQ Controls' ? getAQControls : 
            tabValue === 'Transfection Controls' ? getTransfectionControls :
              getEmptyWells
          }
          hasSpecialistPermission={permisionsList.includes('Quality-Quality Control Specialist')}
          pageData={pageData}
          handleBarcodeClick={getPlateBarcodes}
          hasCreatePermission={formulationsIdList.length > 0}
        />

        <Box sx={{ marginBottom: 1 }}>
          <Tabs
            value={tabValue}
            onChange={(e, newValue) => setTabValue(newValue)}
          >
            <Tab label='LNP Samples' value='LNP Samples' />
            <Tab label='AQ Controls' value='AQ Controls' />
            <Tab 
              label='Transfection Controls' 
              value='Transfection Controls' 
            />
            <Tab label='Empty Wells' value='Empty Wells' />
          </Tabs>
        </Box>

        <TableWithCheckbox 
          heading={tableHeading} 
          rows={formulationList} 
          navigateToEdit={navigateToEdit}
          setDeleteModalOpen={setDeleteModalOpen}
          setId={setSampleId}
          pageData={pageData}
          idKey={tabValue === 'LNP Samples' ? 'lnp_sample_id': 'id'}
          isDeleteBtnVisible={permisionsList.includes('Analytics-Manage Data')}
          isCommentBtnVisible={tabValue === 'LNP Samples' ? permisionsList.includes('Analytics-Manage Comment') : false}
          formulationsIdList={formulationsIdList}
          setFormulationsIdList={setFormulationsIdList}
          setIsBulkDelete={setIsBulkDelete}
        />

        {formulationList.length === 0 && <NoData />}

        {(formulationList.length !== 0 && pageData.current.pageCount !== 1) && <Pagination 
                                    style={{marginTop: '10px', float: 'right'}} 
                                    count={pageData.current.pageCount} 
                                    onChange={handlePaginationClick}/>}
        
        
        <CommentModalForm 
          show={showCommentModal} 
          setShow={setShowCommentModal} 
          comment={comment} 
          setComment={setComment} 
          addComment={addComment} 
        />

        <DeleteModal
          title={
            tabValue === 'LNP Samples' ? deleteLnpSampleModalTitle :
              tabValue === 'AQ Controls' ? deleteAqControlModalTitle :
                tabValue === 'Transfection Controls' ? deleteTransfectionControlModalTitle :
                  deleteEmptyWellsModalTitle
          } 
          message={
            tabValue === 'LNP Samples' ? deleteLnpSampleModalMessage :
              tabValue === 'AQ Controls' ? deleteAqControlModalMessage :
                tabValue === 'Transfection Controls' ? deleteTransfectionControlModalMessage :
                  deleteEmptyWellsModalMessage
          } 
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          onDelete={
            tabValue === 'LNP Samples' ? 
              (isBulkDelete ? bulkDelete : deleteLNPSample) 
            : (tabValue === 'AQ Controls') ? 
              deleteAQControl : (tabValue === 'Transfection Controls') ? 
              deleteTransfectionControl : deleteEmptyWell
          }
        />

        <DeleteModal 
          title={barcodeQualityModalTitle}
          message={barcodeQualityModalMessage}
          open={showBarcodeQualityModal}
          setOpen={setShowBarcodeQualityModal}
          onDelete={plateBarcodeToggle}
        />

        {plateModalOpen && 
          <PlateBarcodeModal 
            open={plateModalOpen}
            setOpen={setPlateModalOpen}
            plateBarcodesList={plateBarcodesList}
            uncheckedBarcodes={uncheckedBarcodes}
            handlePlatesChange={handlePlatesChange}
            plateBarcodeToggle={handleTogglePlateBarcode}
          />
        }

        <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
      </div>
    </AutoLogout>
  );
};

export default FormulationsPage;