import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import FormHelperText from '@mui/material/FormHelperText';

const FileDropZone = ({ 
  file, setFile, fileTypeList, dataType, setDataType, 
  apiRes, setApiRes, setShowInfo, formErrors, onSubmit,
  organisation, organisationsList, ownedBy, setOwnedBy 
}) => {
  const validateFile = sfile =>{
    if (sfile.length === 1) {
      if (sfile[0].name) {
        if (sfile[0].name.split('.').slice(-1).pop() === 'csv') {
          setFile(sfile[0]);
        } else {
          setApiRes({
            ...apiRes,
            axiosError: true,
            errHeading: 'File Upload',
            errMsg: 'please upload a csv file.',
          });
        }
      } else {
        setApiRes({
          ...apiRes,
          axiosError: true,
          errHeading: 'File Upload',
          errMsg: 'File upload error. Please upload a valid csv file.',
        });
      }
    } else {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errHeading: 'File Upload',
        errMsg: 'File upload error. Please upload a single csv file.',
      });
    }
  };

  const handleShowInfo = () => {
    setShowInfo(true);
  };

  const getFileDropWidth = (size) => {
    switch (size) {
      case 'xs':
        return 12;
      case 'md':
        if (organisation === 'CPI') {
          return 5.5;
        }
        return 7.5;
      case 'lg':
        if (organisation === 'CPI') {
          return 6.5;
        }
        return 8;
      case 'xl':
        if (organisation === 'CPI') {
          return 7.75;
        }
        return 9;
      default:
        return 12;
    }
  };

  return(
    <Grid container spacing={2} style={{ marginTop: 1 }}>
      <Grid 
        item
        xs={getFileDropWidth('xs')} 
        md={getFileDropWidth('md')} 
        lg={getFileDropWidth('lg')} 
        xl={getFileDropWidth('xl')}
      >
        <Box sx={{
          height: 150,
          border: (
            (!file && 'file' in formErrors) ? '2px dashed #d32f2f' : '2px dashed black'
          ),
          textAlign: 'center',
          cursor: 'pointer',
          backgroundColor: 'white',
        }}>
          <Dropzone onDrop={acceptedFiles => validateFile(acceptedFiles)}>
          {({getRootProps, getInputProps}) => (
            <section style={{ marginTop: '30px' }}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <FileUploadIcon fontSize='large' />
                <p><b>Drag 'n' drop csv file here, or click to select file</b></p>
                { file && <p><i>{file.name}</i></p> }
              </div>
            </section>
          )}
          </Dropzone>
        </Box>
        { 
          (!file && 'file' in formErrors) && 
          <p style={{ color: '#d32f2f', fontSize: '12px' }}>{formErrors.file}</p> 
        }
      </Grid>

      <Grid item xl={1.5} lg={2} md={2} sm={6} xs={6}>
        <FormControl style={{ width: '100%' }}  error={'dataType' in formErrors}>
          <InputLabel id="type">Type</InputLabel>
          <Select
            labelId="type"
            id="type"
            name="type"
            value={dataType}
            label="type"
            onChange={e => setDataType(e.target.value)}
            sx={{ backgroundColor: 'white' }}
          >
            {fileTypeList.map((data, index) => (
              <MenuItem value={data} key={index}>{data}</MenuItem>
            ))}
          </Select>
          <FormHelperText>{formErrors.dataType}</FormHelperText>
        </FormControl>
      </Grid>
      
      { organisation === 'CPI' &&
        <Grid item xl={1.25} lg={1.5} md={2} sm={6} xs={6}>
          <FormControl style={{ width: '100%' }}  error={'dataType' in formErrors}>
            <InputLabel id="type">Owned By</InputLabel>
            <Select
              labelId="organisation"
              id="organisation"
              name="organisation"
              value={ownedBy}
              label="Owned By"
              onChange={e => setOwnedBy(e.target.value)}
              sx={{ backgroundColor: 'white' }}
            >
              {organisationsList.map((data, index) => (
                <MenuItem value={data} key={index}>{data}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{formErrors.dataType}</FormHelperText>
          </FormControl>
        </Grid>
      }
      
      <Grid item xl={1} lg={1.25} md={1.75} sm={4} xs={4}>
        <Button
          variant="contained" 
          color="success"
          onClick={onSubmit}
          sx={{ width: '100%' }}
        >
          Upload
        </Button>
      </Grid>

      <Grid item xl={0.25} lg={0.25} md={0.75} sm={2} xs={2}>
        <Tooltip title="View columns" arrow>
          <IconButton
            size="small"
            aria-label="show columns"
            color="inherit"
            onClick={handleShowInfo}
          >
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

FileDropZone.propTypes = {
  file: PropTypes.instanceOf(File),
  setFile: PropTypes.func.isRequired,
  fileTypeList: PropTypes.array.isRequired,
  dataType: PropTypes.string.isRequired,
  setDataType: PropTypes.func.isRequired,
  apiRes: PropTypes.object.isRequired,
  setApiRes: PropTypes.func.isRequired,
  setShowInfo: PropTypes.func.isRequired,
  formErrors: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  organisation: PropTypes.string.isRequired,
  organisationsList: PropTypes.array.isRequired,
  ownedBy: PropTypes.string.isRequired,
  setOwnedBy: PropTypes.func.isRequired,
};

export default FileDropZone;