import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import PlateBarcodeItem from './PlateBarcodeItem';

const style = {
  position: 'absolute',
  top: '400px',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  padding: '20px',
  paddingLeft: '40px',
  overflow: "scroll",
  display:'block',
  maxHeight:'80%',
};

const PlateBarcodeModal = ({ 
  open, setOpen, plateBarcodesList, uncheckedBarcodes, 
  handlePlatesChange, plateBarcodeToggle
}) => {
  const submitForm = e => {
    e.preventDefault();
    handleClose();
    plateBarcodeToggle();
  };  

  const handleClose = () => {
    setOpen(false);
  };

  return(
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>  
          <Box sx={style} id='filterForm'>            
            <Grid container spacing={1}>
              <Grid container spacing={1} style={{ margin: '30px 20px 10px 25px'}}>
                <Grid container spacing={3}>
                  <Typography id="title" variant="h5" component="h5" color={'text.secondary'}>
                    <strong>Plates</strong>
                  </Typography>
                </Grid>
              </Grid>

              <Typography id="description" variant='caption' color={'text.secondary'}>
                <i>Only selected barcodes(s) will be included for Query Calculation.</i>
              </Typography>

              <Grid 
                container 
                spacing={1} 
                sx={{ 
                  marginTop: '0px', 
                  marginLeft: 0, 
                  border: '1px dotted grey',
                  borderRadius: '5px',
                  padding: 1.5
                }
              }>
                {
                  plateBarcodesList.map((plateObj, index) => (
                  <Grid item xs={12} key={index}>
                    <Typography id={`plate-${index}`} variant='h6'>
                      <strong>{plateObj.plate_name}</strong>
                    </Typography>

                    {
                      plateObj.dls_barcode_list.length > 0 &&
                      <PlateBarcodeItem 
                        key={`DLS-${index}`}
                        type={'DLS'}
                        plateName={plateObj.plate_name}
                        barcodeList={plateObj.dls_barcode_list}
                        inactiveBarcodesList={uncheckedBarcodes?.dls}
                        handlePlatesChange={handlePlatesChange}
                      />
                    }

                    {
                      plateObj.ribogreen_barcode_list.length > 0 &&
                      <PlateBarcodeItem 
                        key={`Ribogreen-${index}`}
                        type={'Ribogreen'}
                        plateName={plateObj.plate_name}
                        barcodeList={plateObj.ribogreen_barcode_list}
                        inactiveBarcodesList={uncheckedBarcodes?.ribogreen}
                        handlePlatesChange={handlePlatesChange}
                      />
                    }

                    {
                      plateObj.transfection_barcode_list.length > 0 &&
                      <PlateBarcodeItem 
                        key={`Transfection-${index}`}
                        type={'Transfection'}
                        plateName={plateObj.plate_name}
                        barcodeList={plateObj.transfection_barcode_list}
                        inactiveBarcodesList={uncheckedBarcodes?.transfection}
                        handlePlatesChange={handlePlatesChange}
                      />
                    }
                  </Grid>
                  ))
                }
              </Grid>

              <Grid container spacing={1} style={{ marginTop: '0px'}}>
                <Grid item xs={1}>
                  <Button variant="outlined" color='secondary' onClick={handleClose}>Close</Button> 
                </Grid>

                <Grid item xs={1}>
                  <Button 
                    variant="contained" 
                    className='taraPrimaryBtn'
                    onClick={submitForm}
                  >
                    Apply
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

PlateBarcodeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  plateBarcodesList: PropTypes.array.isRequired,
  uncheckedBarcodes: PropTypes.object.isRequired,
  handlePlatesChange: PropTypes.func.isRequired,
  plateBarcodeToggle: PropTypes.func.isRequired,
};

export default PlateBarcodeModal;