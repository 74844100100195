import { useContext } from "react";
import PropTypes from 'prop-types';

import NotificationContext from "../../context/NotificationContext";

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import Tooltip from "@mui/material/Tooltip";
import SearchBar from "../layout/SearchBar";

import BarcodeIcon from "../layout/BarcodeIcon";

const FormulationsHeader = ({ 
  title, handleClick, hasCreatePermission, search, pageData, 
  setIsBulkDelete, setDeleteModalOpen, handleBarcodeClick,
  hasSpecialistPermission
}) => {
  const { notificationCount, handleNotificationClick } = useContext(NotificationContext);

  const handleDeleteClick = () => {
    setIsBulkDelete(true);
    setDeleteModalOpen(true);
  };

  const getGridWidth = (size) => {
    switch (size) {
      case 'xs':
        return 12;
      case 'md':
        return 12;
      case 'lg':
        if (hasSpecialistPermission && hasCreatePermission) {
          return 3.25;
        } else if (hasCreatePermission) {
          return 3.75;
        } else if (hasSpecialistPermission) {
          return 5.75;
        }
        return 6.25;
      case 'xl':
        if (hasSpecialistPermission && hasCreatePermission) {
          return 4.5;
        } else if (hasCreatePermission) {
          return 5;
        } else if (hasSpecialistPermission) {
          return 6.5;
        }
        return 7;
      default:
        return 12;
    }
  };

  const getSearchMDWidth = () => {
    if (hasSpecialistPermission && hasCreatePermission) {
      return 6;
    } else if (hasCreatePermission) {
      return 7;
    } else if (hasSpecialistPermission) {
      return 10;
    }
    return 11;
  };

  return(
    <Grid container spacing={1} style={{ marginTop: 2 }}>
      <Grid 
        item 
        xs={getGridWidth('xs')} 
        md={getGridWidth('md')} 
        lg={getGridWidth('lg')} 
        xl={getGridWidth('xl')}
      >
        <Typography 
          component="h4" 
          variant="h4" 
          color={'text.secondary'} 
        >
          <strong>{title}</strong>
        </Typography>
      </Grid>

      <Grid item xs={12} md={getSearchMDWidth()} lg={5} xl={4.5}>
        <SearchBar search={search} pageData={pageData} />
      </Grid>

      {hasCreatePermission &&
        <Grid item xs={6} md={2} lg={1.25} xl={1}>
          <Button
            variant="contained" 
            color="success"
            onClick={handleClick}
            style={{
              width: '100%'
            }}
          >
            Comment
          </Button>
        </Grid>
      }

      {hasCreatePermission &&
        <Grid item xs={6} md={2} lg={1.25} xl={1}>
          <Button
            variant="contained" 
            color="error"
            onClick={handleDeleteClick}
            style={{
              width: '100%'
            }}
          >
            Delete
          </Button>
        </Grid>
      }

      { hasSpecialistPermission &&
        <Grid item xs={6} md={1} lg={0.5} xl={0.5}>
          <Box 
            justifyContent="center"
            alignItems="center"
            sx={{
              marginTop: -1,
            }}
          >
            <Tooltip title="Plate Barcodes" arrow>
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
                onClick={handleBarcodeClick}
              >
                <BarcodeIcon style={{ fontSize: 35 }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      }

      <Grid item xs={6} md={1} lg={0.75} xl={0.5}>
        <Box 
          justifyContent="center"
          alignItems="center"
          sx={{
            marginTop: -1,
          }}
        >
          <Tooltip title="View notifications" arrow>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={handleNotificationClick}
            >
              <Badge badgeContent={notificationCount} color="error">
                <NotificationsIcon 
                  color="action" 
                  style={{
                    width: 30,
                    height: 30,
                  }}
                />
              </Badge>
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  );
};

FormulationsHeader.propTypes = {
  title: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  hasCreatePermission: PropTypes.bool.isRequired,
  hasSpecialistPermission: PropTypes.bool.isRequired,
  search: PropTypes.func.isRequired,
  setIsBulkDelete: PropTypes.func.isRequired,
  setDeleteModalOpen: PropTypes.func.isRequired,
  pageData: PropTypes.object.isRequired,
  handleBarcodeClick: PropTypes.func.isRequired,
};

export default FormulationsHeader;